import styled from 'styled-components';
import { themeGet } from 'styled-system';
import BannerBG from '../../../images/banner-bg.jpg';

export const BannerInner = styled.div`
  width: 100%;
  margin-top: -100px;
  margin-left: 100px;
  @media (max-width: 990px) {
    margin-left: 0;
  }
`;

export const HomeQuote = styled.div`
  width: 460px;
  max-width: 100%;
  background-color: transparent;
  border-radius: 6px;
  padding: 45px;
  @media (max-width: 990px) {
    width: 400px;
  }

  @media (max-width: 767px) {
    padding: 25px;
    width: 220px;
  }

  .featured_post {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Title = styled.div`
  color: #292929;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: ${themeGet('fontFamily.0', "'Fira Sans',sans-serif")};
  letter-spacing: 0.17em;
  position: relative;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-size: 12px;
    font-weight: 600;
  }

  &:after {
    content: '';
    width: 80px;
    height: 1px;
    background: #292929;
    display: block;
    margin-top: 8px;
  }
`;
