import { Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import CheckedLineItem from '../../../components/CheckedLinteItem';
import MainLogoAvatar from '../../../MainLogoAvatar';
import InfoWrapper from './style';

type InfoProps = {};

const useStyles = makeStyles((theme) => ({
  mainBlurbContainer: {
    marginBottom: theme.spacing(4),
  },
  mainBlurb: {
    padding: theme.spacing(2),
    letterSpacing: '0.17em',
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  icon: {
    fontSize: '3rem',
  },
  infoCardsContainer: {
    marginBottom: theme.spacing(5),
  },
  infoCard: {
    height: '100%',
  },
  infoCardHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  assistContainer: {
    marginTop: theme.spacing(3),
  },
}));

const Info: React.FunctionComponent<InfoProps> = () => {
  const classes = useStyles();
  return (
    <InfoWrapper>
      <Grid className={classes.mainBlurbContainer} container justify="center">
        <Grid item>
          <Typography variant="h1">Why choose me?</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.infoCardsContainer}>
        <Grid item md={4} xs={12}>
          <Card raised className={classes.infoCard}>
            <CardHeader
              className={classes.infoCardHeader}
              title={<Typography variant="h6">Looking at the big picture</Typography>}
              avatar={<MainLogoAvatar />}
            />
            <CardContent>
              <Typography variant="body1">
                Some children may need help with self-settling, but this is often not the full story. My holistic sleep plans consider many other
                factors that may be impacting your child&apos;s sleep, including sleep environment, nutrition, nap timings, nap durations, wake-up
                times, bed times, sleep associations and medical considerations.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card raised className={classes.infoCard}>
            <CardHeader
              className={classes.infoCardHeader}
              title={<Typography variant="h6">There's more than one way</Typography>}
              avatar={<MainLogoAvatar />}
            />
            <CardContent>
              <Typography variant="body1">
                One common misconception is that sleep training always means &apos;Cry It Out&apos;. This is certainly not the case. Children are all
                different plus each family has their own beliefs and parenting styles, so if your plan includes a sleep training element I will always
                provide multiple options and work closely with you to find a method that you&apos;re totally happy with.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card raised className={classes.infoCard}>
            <CardHeader
              className={classes.infoCardHeader}
              title={<Typography variant="h6">Trust in science</Typography>}
              avatar={<MainLogoAvatar />}
            />
            <CardContent>
              <Typography variant="body1">
                With so much sleep information out there it&apos;s hard to know what to trust. I have a first class science degree and my
                recommendations are all based on science and evidence. Your sleep plan will not only explain what to do, but also why you are doing
                it. In my experience, this gives families the confidence to stick with their plans and improve their child&apos;s sleep as quickly as
                possible.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container className={classes.assistContainer}>
        <Grid item xs={12}>
          <Card raised className={classes.infoCard}>
            <CardHeader
              className={classes.infoCardHeader}
              title={<Typography variant="h6">As a trained and certified sleep consultant, I can assist with:</Typography>}
              avatar={<MainLogoAvatar />}
            />
            <CardContent>
              <Typography variant="body1">
                <Grid container justify="center">
                  <Grid item xs={12} md={4}>
                    <CheckedLineItem lineItemText="Improving and extending naps" />
                    <CheckedLineItem lineItemText="Moving away from rocking, cuddling or feeding your child to sleep" />
                    <CheckedLineItem lineItemText="Teaching your child to sleep through the night" />
                    <CheckedLineItem lineItemText="Dummy dependencies" />
                    <CheckedLineItem lineItemText="Teaching self-settling skills" />
                    <CheckedLineItem lineItemText="Early morning wake-ups" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CheckedLineItem lineItemText="Establishing predictable and consistent daytime routines" />
                    <CheckedLineItem lineItemText="Sleep regressions" />
                    <CheckedLineItem lineItemText="Teaching your toddler to stay in bed" />
                    <CheckedLineItem lineItemText="Transitioning away from co-sleeping" />
                    <CheckedLineItem lineItemText="Bedtime battles" />
                    <CheckedLineItem lineItemText="And so much more!" />
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </InfoWrapper>
  );
};

export default Info;
