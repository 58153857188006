import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomePage from '../containers/HomePage';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO title="Home" description="Main home page of Sweet Dreams Jellybean. Certified sleep consultant. I'm here to help your child love sleep." />
      <HomePage />
    </Layout>
  );
};

export default AboutPage;
