import React from 'react';
import HomePage from '../../../images/home-bg.jpg';
import { BannerInner, HomeQuote, Title } from './style';
import { BannerWrapper } from '../../../components/common-styles';

type BannerProps = {};

const BannerComponent = BannerWrapper(HomePage);

const Banner: React.FunctionComponent<BannerProps> = () => {
  return (
    <BannerComponent>
      <BannerInner>
        <HomeQuote>
          <Title>I'm here to help your child love sleep</Title>
        </HomeQuote>
      </BannerInner>
    </BannerComponent>
  );
};

export default Banner;
