import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import materialTheme from '../../theme/material-theme';
import Banner from './Banner';
import Info from './Info';
import HomeWrapper from './style';

type HomePageProps = {};

const HomePage: React.FunctionComponent<HomePageProps> = ({ ...props }) => {
  return (
    <ThemeProvider theme={materialTheme}>
      <HomeWrapper {...props}>
        <Banner />
        <Info />
      </HomeWrapper>
    </ThemeProvider>
  );
};

export default HomePage;
